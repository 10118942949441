import { AnteriorityUnits } from '../enums/anteriority-units.enum';

export class PainZoneExtended {
  id: string;
  label: string;
  level: number;
  intensity: number;
  bodyZoneId: string;
  bodyZoneLabel: string;
  deleted: boolean;
  painAnteriorityUnit: AnteriorityUnits;
  painAnteriorityValue: number;
  observation: string;
  propagationId?: string;
  propagationPosition?: number;

  // internal
  elements: SVGElement[];
  propagatedZone?: boolean;
  edited?: boolean;

  /**
   *
   */
  constructor(data: any) {
    this.id = data?.id || '00000000-0000-0000-0000-000000000000';
    this.level = data?.level;
    this.label = `${data?.level === 1 ? 'Zone de douleur généralisée' : 'Zone de douleur localisée'}<br/>${data?.bodyZoneLabel}`;
    this.intensity = data?.intensity;
    this.bodyZoneId = data?.bodyZoneId;
    this.bodyZoneLabel = data?.bodyZoneLabel;
    this.deleted = data?.deleted;
    this.painAnteriorityUnit = data?.painAnteriorityUnit || AnteriorityUnits.None;
    this.painAnteriorityValue = data?.painAnteriorityValue;
    this.observation = data?.observation;
    this.propagationId = data?.propagationId;
    this.propagationPosition = data?.propagationPosition;
    this.intensity = data?.intensity || 0;

    this.propagatedZone = !!data?.propagationId;

    this.elements = [null, null, null, null, null, null, null, null];
  }
}
